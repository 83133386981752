.auth {
    margin: 0 auto;
    width: 100%;
    max-width: rem(750);
    background: $white;
    padding: rem(60) rem(20);
    box-shadow: 0 0 10px 0 rgba(27,55,100, 0.15);

    &__header {
        color: $black;
        font-weight: 400;
        line-height: 1.2;
        font-size: rem(28);
        text-align: center;
        position: relative;
        font-family: $font-family-secondary;
        padding-bottom: 23px;
        margin-bottom: rem(24);

        &:before {
            bottom: 0;
            left: calc(50% - 25px);
            content: '';
            width: 50px;
            height: 3px;
            position: absolute;
            background: $red;
        }
    }

    &__subtitle {
        text-align: center;
        margin-bottom: rem(24);
    }

    &__switcher {
        margin: 0;
        padding: 0;
        color: $gray;
        border: none;
        outline: none;
        cursor: pointer;
        text-align: center;
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(24);
        background: transparent;
        display: inline-block;

        span {
            color: $red;
            border-bottom: 1px solid $red;
        }
    }

    &__main {
        margin: 0 auto;
        max-width: rem(550);
    }

    &__reg {
        display: none;
    }

    &.-auth-reg- {

        .auth {

            &__enter {
                display: none;
            }

            &__reg {
                display: block;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: rem(60) rem(40);

        &__header {
            font-size: rem(36);
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem(80) rem(40);

        &__header {
            font-size: rem(42);
        }

        &__subtitle {
            margin-bottom: rem(32);
        }
    }
}
