input:not([type=checkbox]):not([type=radio]),
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input, textarea {
    outline: none;
    &:focus:required:invalid { border-color: red }
    &:required:valid { border-color: green }
}

:active, :hover, :focus {
    outline: 0 !important;
    outline-offset: 0 !important;
}

option::selection {background: #ccc;}
option::-moz-selection {background: #ccc;}
option::-webkit-selection {background: #ccc; color:#fff;}

.field {
    width: 100%;
    display: block;
    font-weight: 400;
    position: relative;
    background: transparent;
    $input-selector: "input[type=text], input[type=email], input[type=password], input[type=number], textarea, select";

    #{$input-selector} {
        margin: 0;
        width: 100%;
        height: rem(52);
        outline: none;
        color: #444;
        box-shadow: none;
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(24);
        padding: rem(13) rem(16);
        background: #fff;
        display: inline-block;
        vertical-align: middle;
        border: rem(1) solid #d9dade;
        border-radius: 8px;
        @include placeholder(#9A9CA5);

        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        &:focus,
        &:active {
            outline: none;
            border-color: rgba(255, 63, 58, 0.2);
            @include placeholder(transparent);
        }


        &.error {
            border-color: red !important;
        }
    }

    textarea {
        height: auto;
        resize: none;
    }

    &__icon {
        fill: #9A9CA5;
        font-size: 0;
        line-height: 0;
        top: calc(50% - 0.625rem);
        left: rem(16);
        height: rem(20);
        position: absolute;
        display: inline-block;
    }

    &__arrow {
        z-index: 1;
        fill: $dark;
        font-size: 0;
        line-height: 0;
        display: inline-block;
        top: calc(50% - 10px);
        right: rem(16);
        height: 20px;
        position: absolute;
    }

    &--icon {
        background: $white;

        #{$input-selector} {
            padding-left: rem(52);
            background: transparent;
        }
    }


    &--select {

        #{$input-selector} {
            padding-right: rem(48);
        }
    }
}

.field-info {
    margin-top: rem(8);
    font-size: rem(12);
    line-height: rem(16);

    @include media-breakpoint-up(xl) {
        font-size: rem(14);
        line-height: rem(20);
    }
}

.error {
    color: brown;
    padding-top: rem(4);
    font-size: rem(12);
    line-height: rem(14);
}
