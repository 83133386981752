.page {
    padding-top: rem(96);
    padding-bottom: rem(40);
    max-width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__main {
        flex-grow: 1;
        width: 100%;
        margin: 0 auto;
        z-index: 10;
        position: relative;
        max-width: rem(1280);
        padding: 0 rem(16);
        display: flex;
        flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
        padding-top: rem(72);
        padding-bottom: rem(72);
        background-color: $blue-2;

        &__main {
            padding: 0 rem(32);

            &:before {
                z-index: 1;
                content: '';
                top: rem(32);
                left: rem(32);
                right: rem(32);
                bottom: rem(-48);
               // height: rem(200);
                position: absolute;
                background: #2F3CED;
                mix-blend-mode: multiply;
                opacity: 0.4;
                filter: blur(43.4925px);
                border-radius: 24px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem(96) 0;
    }
}

.header-mobile {
    top: 0;
    left: 0;
    right: 0;
    z-index: 800;
    position: fixed;
    background: $white;
    border-bottom: 1px solid #f5f5fa;

    &__wrap {
        max-width: rem(1280);
        margin: 0 auto;
        height: rem(70);
        padding: rem(8) rem(16);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        display: block;
        width: rem(48);
        height: rem(48);
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #f1f1f1;
        flex-shrink: 0;
    }

    &__nav {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        position: absolute;
        border-bottom: none;
        background: transparent;

        &__logo {
            display: none;
        }

        &__hamburger {
            display: none;
        }


        &__nav {
            width: 100%;
            white-space: nowrap;
            font-weight: 600;
            font-size: rem(16);
            line-height: rem(24);
            display: flex;
            align-items: center;
            justify-content: flex-end;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;

                li {
                    margin-left: rem(36);

                    a {
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {

        &__wrap {
            height: rem(96);
        }
    }
}

.dashboard {
    z-index: 20;
    flex-grow: 1;
    position: relative;
    background: #FDFDFD;
    border-radius: 24px;

    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.dashboard_main {
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
        padding: rem(40);
    }


    @include media-breakpoint-up(xl) {
        padding: rem(60) rem(50) rem(60) rem(60);
    }
}

.navbar {
    display: none;

    &__menu {
        margin: 0;
        padding: 0;
        list-style: none;
        font-weight: 600;
        font-size: rem(16);
        line-height: rem(20);

        li {

            a {
                fill: #808191;
                color: #808191;
                padding: rem(8) rem(24);
                text-decoration: none;
                border-radius: 0px 12px 12px 0px;
                display: flex;
                align-items: center;
                height: rem(56);

                &:before {

                }

                i {
                    font-size: 0;
                    line-height: 0;
                    height: rem(24);
                    flex-shrink: 0;
                    margin-right: rem(16);
                    display: inline-block;
                }

                span {
                    display: inline-block;
                }

                &:hover {
                    fill: #2F3CED;
                    color: #2F3CED;
                    text-decoration: none;
                }
            }

            &.active {

                a {
                    fill: $white;
                    color: $white;
                    background: #2F3CED;
                }
            }
        }
    }

    &__logo {
        display: none;
    }

    &__account {
        padding: rem(30)rem(24);
        border-top: 1px solid #E4E4E4;
    }

    @include media-breakpoint-up(lg) {
        display: block;
        padding-top: rem(32);
        width: rem(256);
        flex-shrink: 0;
        border-right: 1px solid #E4E4E4;

        &__menu {
            margin-bottom: rem(24);
            padding-right: rem(40);
        }

        &__logo {
            display: block;
            width: rem(64);
            height: rem(64);
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid #f1f1f1;
            flex-shrink: 0;
            margin-left: rem(32);
            margin-bottom: rem(32);
        }
    }

    @include media-breakpoint-up(xl) {
        width: rem(300);
    }
}

.user {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    font-size: rem(14);
    line-height: rem(20);
    background: transparent;
    color: $black;
    text-decoration: none;
    display: flex;
    align-items: center;

    &__avatar {
        width: rem(40);
        height: rem(40);
        overflow: hidden;
        border-radius: 50%;
    }

    &__content {
        padding-left: rem(16);
    }

    &__name {
        font-weight: 600;
    }

    &__exit {
        font-size: 0;
        line-height: 0;
        fill: #808191;
        height: rem(24);
        margin-left: rem(20);
        display: inline-block;
    }
}

.dash-wrapper {
    max-width: rem(600);
}


@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}
