.pretty-checkbox {
    position: relative;

    &__input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:checked + .pretty-checkbox__label {

            &::before {
                background-color: $white;
                border-color: rgba(255, 63, 58, 1);
            }

            &::after {
                opacity: 1;
                border-top-style : none;
                border-right-style : none;
                transform : rotate(-45deg);
                height : 0.4rem;
                width : 0.8rem;
                border-color : rgba(255, 63, 58, 1);
                border-radius : 0;
                border-width : 2px;
                top : rem(5);
                left : rem(4);
            }
        }

        &:disabled + .pretty-checkbox__label {
            opacity: 0.4;
        }
    }

    &__label {
        color: #3a3a3a;
        cursor: pointer;
        font-size: rem(16);
        line-height: rem(20);
        position: relative;
        padding-left: rem(30);
        display: inline-block;

        &--sm {
            font-size: rem(13);
        }

        &::before {
            top: 0;
            left: 0;
            z-index: 1;
            content: '';
            width: rem(20);
            height: rem(20);
            display: block;
            position: absolute;
            border: 1px solid #d9dade;
            border-radius: rem(3);
        }

        &:hover {

            &:before {
                border-color: rgba(255, 63, 58, 0.5);
            }
        }

        &:after {
            z-index: 5;
            content : '';
            opacity: 0;
            height : rem(20);
            width : rem(20);
            border : 1px solid rgba(255, 63, 58, 1);
            position : absolute;
            border-radius : rem(4);
            top : 0;
            left : 0;
            -webkit-transition : 100ms ease-in-out;
            transition : 100ms ease-in-out;
        }
    }

}
