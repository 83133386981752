[data-dropdown] {
    position: relative;

    &.open {
        z-index: 50;

        [data-dropdown-content] {
            opacity: 1;
            visibility: visible;
        }
    }
}

[data-dropdown-toggle] {
    cursor: pointer;
}

[data-dropdown-content] {
    top: 100%;
    left: 0;
    margin-top: rem(4);
    min-width: 100%;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.dropdown {
    display: inline-flex;
    flex-direction: column;

    &__content {
        background: $white;
        border-radius: rem(4);
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);
    }

    &__item {
        cursor: pointer;
        min-width: 100%;
        display: inline-flex;
        padding: rem(6) rem(20);

        &:hover {
            background-color: #eee;
        }
    }
}
