.product {
    margin-bottom: rem(60);

    &__forward {
        fill: $red;
        color: $dark-blue;
        text-decoration: none;
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(24);
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        margin-bottom: rem(24);

        i {
            height: rem(24);
            font-size: 0;
            line-height: 0;
            flex-shrink: 0;
            margin-right: rem(12);
            display: inline-block;
        }

        &:hover {
            color: $red;
            text-decoration: none;
        }
    }

    &__media {
        margin-bottom: rem(32);
    }

    &__header {
        color: $dark-blue;
        margin-bottom: rem(24);
    }

    &__info {
        padding-top: rem(8);
    }

    &__description {
        margin-top: rem(40);
    }

    &__order {
        margin-top: rem(24);
    }

    &__start {
        margin-top: rem(24);
    }

    &__subscribe {
        margin-top: rem(32);
    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem(80);
    }

    @include media-breakpoint-up(lg) {


        &__row {
            display: flex;
        }


        &__media {
            width: rem(400);
            margin-bottom: 0;
            flex-shrink: 0;
        }

        &__content {
            margin-left: rem(32);
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(120);

        &__media {
            width: rem(520);
            margin-bottom: 0;
            flex-shrink: 0;
        }

        &__content {
            margin-left: rem(60);
        }

        &__subscribe {
            margin-top: rem(42);
            margin-bottom: rem(48);
        }
    }
}

.product-subscribe {
    border-radius: 12px;
    padding: rem(24) rem(24) rem(36) rem(24);
    background: linear-gradient(55.95deg, rgba(255, 63, 58, 0.15) 0%, rgba(247, 94, 5, 0.15) 100%);

    &__title {
        font-weight: 600;
        font-size: rem(20);
        line-height: 120%;
        margin-bottom: rem(16);
    }

    &__params {
        margin-bottom: rem(16);
    }

    &__button {

        .btn {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        padding: rem(24) rem(36) rem(36) rem(36);

        &__row {
            display: flex;
            flex-wrap: wrap;
        }

        &__title {
            width: 100%;
        }

        &__button {
            width: 220px;
            padding-left: rem(30);
            flex-shrink: 0;
        }

        &__params {
            width: calc(100% - 200px);
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: rem(32);

        &__row {
            flex-wrap: nowrap;
            align-items: center;
        }

        &__title {
            padding-right: rem(30);
            white-space: nowrap;
            width: auto;
            margin-bottom: 0;
        }

        &__params {
            width: auto;
            flex-grow: 1;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem(32) rem(64);

        &__title {
            padding-right: rem(48);
        }
    }
}

.panda-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        padding-left: rem(28);
        position: relative;

        &:not(:last-child) {
            margin-bottom: rem(8);
        }

        &:before {
            left: 0;
            top: rem(5);
            content: '';
            width: rem(16);
            height: rem(16);
            position: absolute;
            background: url("../img/check.svg") 50% 50% no-repeat;
            background-size: cover;
        }
    }

    &--md {

        li {

            &:not(:last-child) {
                margin-bottom: rem(20);
            }
        }
    }

    &--inline {
        display: flex;
        flex-wrap: wrap;
        margin-right: rem(-16);

        li {
            padding-left: rem(24);
            margin-right: rem(24);
        }
    }

    @include media-breakpoint-up(xl) {

        &--md {
            font-size: rem(20);

            li {
                padding-left: rem(36);

                &:before {
                    top: rem(8);
                    width: rem(20);
                    height: rem(20);
                }
            }
        }
    }
}

.warning-block {
    color: $dark;
    padding: rem(20);
    font-weight: 500;
    margin-top: rem(32);
    line-height: 200%;
    border-left: 2px solid $red;
    border-radius: 0 12px 12px 0;
    background: linear-gradient(55.95deg, rgba(255, 63, 58, 0.2) 0%, rgba(247, 94, 5, 0.2) 100%);

    @include media-breakpoint-up(md) {
        padding-left: rem(32);
        padding-right: rem(32);
    }

    @include media-breakpoint-up(xl) {
        padding-left: rem(48);
        padding-right: rem(48);
    }
}

.advantage {
    margin-bottom: rem(60);

    &__header {
        font-weight: 700;
        font-size: 28px;
        line-height: 130%;
        margin-bottom: rem(40);
    }

    @include media-breakpoint-up(md) {
        display: flex;
        margin-bottom: rem(100);

        &__header {
            margin-bottom: 0;
            width: rem(260);
            flex-shrink: 0;
        }

        &__content {
            padding-left: rem(40);
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(120);

        &__header {
            width: rem(490);
            font-size: rem(46);
        }

        &__content {
            padding-left: rem(135);
        }
    }
}

.person {
    margin-bottom: rem(40);

    &__media {
        display: none;
    }

    &__content {
    }


    &__label {
        font-weight: 600;
        font-size: rem(16);
        line-height: 150%;
    }

    &__name {
        font-weight: 700;
        font-size: rem(28);
        line-height: 130%;
        margin-bottom: rem(8);
    }

    &__staff {
        color: #787A80;
        font-weight: 400;
        font-size: rem(14);
        line-height: 150%;
        margin-bottom: rem(16);
    }

    &__text {
        max-width: rem(500);
        margin-bottom: rem(16);
    }

    @include media-breakpoint-up(md) {
        display: flex;

        &__media {
            display: block;
            width: rem(280);
            flex-shrink: 0;
        }

        &__content {
            padding-left: rem(40);
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem(180);
        position: relative;

        &:before {
            width: 240px;
            height: 242px;
            top: -70px;
            left: -140px;
            z-index: 1;
            content: '';
            position: absolute;
            background: url("../img/cirles.svg") 50% 50% no-repeat;
        }

        &__media {
            z-index: 20;
            width: rem(460);
            position: relative;
        }

        &__content {
            line-height: 170%;
            padding-left: rem(120);

            .panda-list {
                display: block;
            }
        }

        &__name {
            font-size: rem(40);
        }

        &__staff {
            font-size: rem(18);
            margin-bottom: rem(32);
        }
    }
}
