@import './inputs';
@import './checkboxes';
@import './radiobuttons';
@import './choices';
@import './range';
@import './quantity';

.form-group {
    margin-bottom: rem(24);
}

.form-label {
    font-size: rem(14);
    font-weight: 600;
}

.form-divider {
    margin-bottom: rem(36);
}

.form-button {

    .btn {
        min-width: rem(200);
    }
}
