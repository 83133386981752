.breadcrumb {
    padding: 0;
    list-style: none;
    margin: 0 0 rem(20) 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__item {
        display: block;

        &:not(:last-child) {
            &::after {
                content: '›';
                padding: 0 rem(6);
                display: inline-block;
            }
        }
    }

    &__link {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
