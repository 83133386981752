@import "node_modules/bootstrap/scss/functions";
@import './layout/fonts';
@import 'config';

@import 'layout/functions';
@import './mixins/mixins';

// -----------------
// Bootstrap v5.2.3
// -----------------

// scss-docs-start import-stack
// Configuration


@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

// Layout & components
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/offcanvas";
@import "node_modules/bootstrap/scss/placeholders";

// Helpers
@import "node_modules/bootstrap/scss/helpers";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack


// Plugins
@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/@fancyapps/ui/src/Fancybox/Fancybox';

// Layout
@import './layout/fonts';
@import './layout/base';
@import './layout/utilities';

@import './components/fullscreen';
@import './components/containers';
// @import './components/grid';
@import './components/buttons';
@import './components/forms/forms';
@import './components/breadcrumbs';
@import './components/tabs';
@import './components/collapse';
@import './components/spoilers';
@import './components/dropdown';
@import './components/rating';


@import 'parts/header';
@import 'parts/footer';
@import 'parts/auth';
@import 'parts/cart';
@import 'parts/modal';
@import 'parts/product';

@import 'content';
@import 'dashboard';



