.cart {

    &__body {
        border-top: 1px solid $light;
    }

    &__footer {
        font-size: rem(20);
        line-height: rem(30);
        padding-top: rem(20);
        font-family: $font-family-secondary;
        display: flex;
        align-items: center;
    }

    &__total {
        margin-right: rem(8);
    }
}

.cart-item {
    padding: rem(16) 0;
    border-bottom: 1px solid $light;
    display: flex;
    align-items: center;

    &__media {
        padding: rem(10) rem(5);
        width: rem(70);
        height: rem(80);
        flex-shrink: 0;
        background: $white;
    }

    &__cover {
        height: rem(60);
    }

    &__content {
        flex-grow: 1;
        padding-left: rem(20);
        padding-right: rem(10);
    }

    &__title {
        color: $blue;
        font-weight: 500;
        font-size: rem(18);
        margin-bottom: rem(4);

        a {
            color: $blue;
            text-decoration: none;

            &:hover {
                color: $blue;
                text-decoration: underline;
            }
        }
    }

    &__price {
        color: $blue;
        font-weight: 700;
        font-size: rem(12);
        margin-top: rem(4);
        font-family: $font-family-secondary;
        text-decoration: none;
    }
}

.order-block {
    border-bottom: 1px solid $light;
    margin-top: rem(40);
    margin-bottom: rem(40);
}
