.footer {
    padding: rem(40) 0;
    color: $white;
    background: #00093C;
    font-size: rem(16);
    line-height: rem(24);

    &__info {
        padding: 0;
        list-style: none;
        margin: 0 0 rem(20) 0;
        text-align: center;

        li {

            &:not(:last-child) {
                margin-bottom: rem(8);
            }

            a {
                color: $white;
                text-decoration: none;

                &:hover {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }

    &__links {
        text-align: center;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {

                &:not(:last-child) {
                    margin-bottom: rem(8);
                }

                a {
                    color: $white;
                    text-decoration: none;

                    &:hover {
                        color: $white;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__social {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;

        li {

            &:not(:last-child) {
                margin-right: rem(12);
            }

            a {
                fill: $dark-blue;
                font-size: 0;
                line-height: 0;
                width: rem(36);
                height: rem(36);
                border-radius: 50%;
                background: $white;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    height: rem(20);
                    display: inline-block;
                    flex-shrink: 0;
                }
            }
        }
    }

    &__email {
        fill: $white;
        color: $white;
        white-space: nowrap;
        text-decoration: none;
        margin-top: rem(20);
        display: inline-flex;
        align-items: center;

        i {
            font-size: 0;
            line-height: 0;
            height: rem(20);
            display: inline-block;
            margin-right: rem(8);
        }

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }

    &__contact {
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        padding: rem(80) 0;

        &__links {
            text-align: right;
            margin-bottom: 0;
        }

        &__info {
            text-align: left;
        }

        &__row {
            display: flex;
            justify-content: space-between;
        }
    }
}
