@mixin fontface($name, $file, $weight: null, $style: normal) {
    @font-face {
        font-family: "#{$name}";
        font-style: $style;
        @if($weight) {
            font-weight: $weight;
        }
        src: url('../fonts/#{$file}.woff2') format('woff2');
    }
}
