.header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 800;
    position: relative;
    background: linear-gradient(55.95deg, rgba(255, 63, 58, 0.15) 0%, rgba(247, 94, 5, 0.15) 100%);
    border-bottom: 1px solid #f5f5fa;


    &--white {
        background: $white;
        border-bottom: 1px solid #f5f5fa;
    }

    &__row {
        height: rem(80);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {

    }

    &__content {
       display: flex;
        align-items: center;
        justify-content: flex-end;

    }

    &__hamburger {
        font-size: 0;
        line-height: 0;
        display: block;
        margin-left: rem(16);
    }

    &__nav {
        display: none;
        margin-right: rem(24);

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-weight: 500;
            font-size: rem(18);
            line-height: rem(24);

            li {
               margin: 0 rem(20);

                a {
                    color: $dark-blue;
                    text-decoration: none;
                    transition: .3s;

                    &:hover {
                        color: $red;
                        text-decoration: none;
                        transition: .3s;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {

        &__row {
            height: rem(90);
        }
    }

    @include media-breakpoint-up(xl) {

        &__hamburger {
            display: none;
        }

        &__nav {
            display: block;

        }
    }

    @include media-breakpoint-up(xl) {
        position: relative;

        &__row {
            height: rem(120);
        }
    }

    @include media-breakpoint-up(xxl) {}
}

.header-logo {
    font-size: 0;
    line-height: 0;
    color: #000c48;
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    &:hover {
        color: #000c48;
        text-decoration: none;
    }

    &__icon {
        display: block;
        width: rem(48);
        height: rem(48);
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #f1f1f1;
    }

    &__name {
        display: none;
        font-weight: 700;
        font-size: rem(20);
        line-height: rem(24);
        padding-left: rem(12);
        white-space: nowrap;
    }

    @include media-breakpoint-up(md) {

        &__name {
            display: block;
        }
    }

    @include media-breakpoint-up(xl) {

        &__logo {
            width: rem(64);
            height: rem(64)
        }
    }
}

.hamburger {
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    display: block;

    span,
    &:after,
    &:before {
        width: 24px;
        height: 2px;
        content: '';
        top: calc(50% - 1px);
        left: calc(50% - 12px);
        display: inline-block;
        position: absolute;
        transition-property: transform;
        transition-duration: .15s;
        transition-timing-function: ease;
        background-color: $red;
    }

    span {

    }

    &:after {
        transform: translateY(-7px);
    }

    &:before {
        transform: translateY(7px);
    }

    &.hamburger--open {

    }
}

.nav-open {

    .hamburger {

        span {
            opacity: 0;
        }

        &:after {
            transform: translateY(0) rotate(45deg);
        }

        &:before {
            transform: translateY(0) rotate(-45deg);
        }
    }
}

.btn-auth {
    padding: 0;
    width: rem(48);

    span {
        display: none;
    }

    @include media-breakpoint-up(md) {
        width: auto;
        padding: rem(8) rem(24);

        span {
            display: inline-block;
        }
    }
}

.nav-mobile {
    left: 0;
    right: 0;
    bottom: 0;
    top: rem(72);
    z-index: 900;
    padding: rem(36) 0;
    position: fixed;
    background: $white;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    &__menu {
        font-weight: 600;
        font-size: rem(16);
        line-height: rem(24);

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {

            &:not(:last-child) {
                margin-bottom: rem(12);
            }

            a {
                padding: rem(4) 0;
                white-space: nowrap;
                fill: $dark-blue;
                color: $dark-blue;
                text-decoration: none;
                transition: .3s;
                display: inline-flex;
                align-items: center;

                span {
                    display: inline-flex;
                }

                i {
                    font-size: 0;
                    line-height: 0;
                    height: rem(20);
                    width: rem(24);
                    flex-shrink: 0;
                    margin-right: rem(16);
                    display: inline-block;
                }

                &:hover {
                    fill: $red;
                    color: $red;
                    text-decoration: none;
                    transition: .3s;
                }
            }
        }
    }

    &__divider {
        padding-top: rem(36);
        margin-bottom: rem(36);
        border-bottom: 1px solid #f5f5fa;
    }
}

.nav-open {
    overflow: hidden;

    .nav-mobile {
        opacity: 1;
        visibility: visible;
        transition: .3s;
    }

    &__auth {
        max-width: rem(260);
    }
}
