.pretty-radio {
    font-size: 0;
    line-height: 0;
    font-weight: 400;
    position: relative;
    display: inline-block;

    &__input {
        @include visually-hidden();
    }

    &__label {
        color: #3a3a3a;
        cursor: pointer;
        font-size: rem(16);
        line-height: rem(20);
        position: relative;
        padding-left: rem(30);
        display: block;

        &::before {
            top: 0;
            left: 0;
            z-index: 1;
            content: '';
            width: rem(20);
            height: rem(20);
            display: block;
            position: absolute;
            border-radius: 50%;
            border: 1px solid #E5E8ED;
            transition: .3s;
        }

        &:after {
            top: rem(5);
            left: rem(5);
            z-index: 2;
            content: '';
            width: rem(10);
            height: rem(10);
            display: block;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            background: rgba(255, 63, 58, 1);
            border-radius: 50%;
         //   transform: scale(3);
        }
    }

    &:hover {

        .pretty-radio__label {

            &:before {
                border-color: rgba(255, 63, 58, 0.5);
                transition: .3s;
            }
        }
    }


    &__input:checked + .pretty-radio__label {

        &::before {
            border-color: rgba(255, 63, 58, 1);
        }

        &::after {
            opacity: 1;
            visibility: visible;
           // transform: scale(1);
            transition : all 0.3s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        }
    }

    &__input:disabled + .pretty-radio__label {
        opacity: 0.4;
    }
}
