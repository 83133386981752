.rating {
    font-size: 0;
    line-height: 0;
    display: inline-flex;
    align-items: center;

    &__body {
        position: relative;
    }

    &__active {
        top: 0;
        left: 0;
        width: 0;
        z-index: 5;
        overflow: hidden;
        position: absolute;
        display: flex;
        fill: gold;
    }

    &__stars {
        display: flex;
        fill: lightgrey;
    }

    &__star {
        flex-shrink: 0;
        height: rem(24);
        display: inline-block;
    }

    &__items {
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: row-reverse;
    }

    &__item {
        color: gold;
        cursor: pointer;
        flex: 0 0 20%;

    }

    &__value {
        top: 2px;
        position: relative;
        font-size: rem(16);
        font-weight: 600;
        line-height: rem(24);
        padding-left: rem(10);
    }
}

[data-rating-readonly='true'] {

    .rating__item {
        cursor: default;
    }
}


.simple-rating {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    font-size: 0;
    line-height: 0;
    flex-direction: row-reverse;


    &__item {
        top: 0;
        left: 0;
        width: 0%;
        height: 0%;
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }

    &__label {
        color: gold;
        font-size: rem(40);
        line-height: rem(40);
        cursor: pointer;
        display: block;
        transition: .3s;

        &:before {
            content: '☆';

        }
    }

    &__item:checked,
    &__item:checked ~ .simple-rating__label {

        &:before {
            content: '★';
        }
    }

    &__label:hover,
    &__label:hover ~ .simple-rating__label,
    &__label:checked ~ .simple-rating__label:hover {

        &:before {
            content: '★';
        }
    }
}
