// Options
$enable-grid-classes:         true !default;


// colors

$white:         #fff !default;
$black:         #000 !default;
$primary:       #000 !default;
$secondary:     #000 !default;

$red:           #FF3F3A !default;
$red-50:        rgba(255, 63, 58, 0.5) !default;
$red-30:        rgba(255, 63, 58, 0.3) !default;
$blue:          #2F3CED !default;
$dark:          #1E212C !default;
$gray:          #787A80 !default;
$dark-blue:     #000c48 !default;
$blue-2:        #3F8CFF !default;
$light:         #F5F8FC !default;

$html-font-size: 16px;
$font-size-base:            1rem !default;
$line-height-base:          1.5 !default;

$font-family-primary: 'Noto Sans', 'Arial', sans-serif;
$font-family-secondary: 'Noto Sans', 'Arial', sans-serif;

$border-radius: rem(4);
$transition-time: 0.2s;
$box-shadow: 0 0 0 #fff;

$paragraph-margin-bottom:   rem(20) !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer / 4,
    2: $spacer / 2,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
) !default;

// Grid

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1230px,
    xxl: 1300px,
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
    xxl: 1220px,
) !default;


$grid-columns:                12 !default;
$grid-gutter-width:           2rem !default;
$grid-row-columns:            6 !default;

$gutters: $spacers !default;

$container-padding-x: $grid-gutter-width !default;


@import "node_modules/bootstrap/scss/variables";
