[data-spoiler-content] {
	overflow: hidden;
	max-height: 0;
	opacity: 0;
	will-change: max-height;
	transition: .3s;
}

[data-spoiler] {

	&.open {

		[data-spoiler-content] {
			opacity: 1;
			transition: .3s;
		}
	}
}

.spoilers {

	&__item {
        background: $white;
        border-radius: 4px;
		margin: 0 0 rem(12) 0;
        border: 1px solid #E5E8ED;
        box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);


        &.open {

			.spoilers__icon {
				transform: rotate(-180deg);
				transition: .3s;
			}
		}
	}

	&__header {
		width: 100%;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: rem(12) rem(16);
		color: $dark-blue;
        border: none;
		outline: none;
		text-align: left;
		background: transparent;
		font-weight: 500;
		font-size: rem(20);
		line-height: rem(26);
	}

	&__title {
		display: block;
		padding-right: rem(8);
	}

	&__icon {
		font-size: 0;
		line-height: 0;
		height: rem(24);
		display: inline-block;
		transition: .3s;
		fill: #000;
		flex-shrink: 0;
	}

	&__content {
        color: $gray;
	}

	&__wrap {
		padding: rem(8) rem(16) rem(16) rem(16);
	}

    @include media-breakpoint-up(xl) {

        &__header {
            padding: rem(16) rem(16) rem(16) rem(24);
        }

        &__wrap {
            padding: rem(8) rem(16) rem(16) rem(24);
        }
    }
}
