.btn {
    outline: none;
    cursor: pointer;
    font-weight: 400;
    height: rem(52);
    font-size: rem(16);
    line-height: rem(20);
    padding: rem(8) rem(24);
    border: 2px solid transparent;
    user-select: none;
    text-align: center;
    white-space: nowrap;
    position: relative;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background: transparent;
    border-radius: 12px;
    transition: .3s;

    &:hover {
        transition: .3s;
    }

    &:active {
    //    top: 1px;
    }

    &:focus,
    &.focus {
        outline: 0;
    }

    span {
        display: inline-block;
    }

    i {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        height: rem(20);
    }

    span + i {
        margin-left: rem(8);
    }

    i + span {
        margin-left: rem(8);
    }
}

.btn-blue {
    fill: $white;
    color: $white;
    background: $blue;

    &:hover,
    &:focus {
        background: darken($blue, 10%);
        border-color: darken($blue, 10%);
    }
}

.btn-red {
    fill: $white;
    color: $white;
    background: $red;

    &:hover,
    &:focus {
        fill: $white;
        color: $white;
        background: darken($red, 10%);
    }

    &:active {
        background: darken($red, 20%);
    }
}

.btn-border-blue {
    fill: $blue;
    color: $blue;
    background: $white;
    border: 2px solid rgba(0,147,220,.3);
}

.btn-border-red {
    fill: $red;
    color: $red;
    background: $white;
    border-color: $red-30;

    &:hover {
        border-color: $red;
    }
}

.btn-link {
    color: $red;
    fill: $red;
    padding: 0;
    height: auto;
    font-weight: 500;

    &:hover {
        fill: $dark-blue;
        color: $dark-blue;
        text-decoration: none;
    }
}

.btn-icon {
    width: rem(48);
}

.btn-lg {
    height: rem(56);
    font-size: rem(18);
    line-height: rem(24);
    padding: rem(8) rem(36);
}


.btn-group {
    margin: 0 rem(-15);

    &__item {
        padding: rem(10) rem(15);
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        align-items: center;
    }
}


.btn-nav {
    width: 48px;
    height: 48px;
    font-size: 0;
    line-height: 0;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $dark-blue;

    i {
        height: 24px;
        display: inline-block;
        flex-shrink: 0;
    }

    &:hover {
        fill: $white;
        background: $red;
    }
}
