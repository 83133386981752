.root {
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;
    padding: rem(40) 0;

    @include media-breakpoint-up(md) {
        padding: rem(40) 0 rem(64) 0;
    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.modal {
    padding: rem(24);

    @include media-breakpoint-up(md) {
        padding: rem(32);
    }
}

.primary {
    padding-top: 224px;
    position: relative;

    &__media {
        top: 0;
        left: calc(50% - 600px);
        width: 894px;
        height: 224px;
        position: absolute;
    }

    &__main {
        color: $white;
        padding: rem(40) 0;
        background: $dark-blue;
    }

    &__header {
        display: block;
        font-size: rem(26);
        line-height: rem(36);

        strong {
            display: block;
            font-size: rem(32);
            line-height: rem(44);
            margin-top: rem(16);
        }
    }

    @include media-breakpoint-up(sm) {

        &__media {
            left: calc(50% - 500px);
        }
    }

    @include media-breakpoint-up(md) {
        padding-top: 0;

        &__media {
            width: 1440px;
            height: 360px;
            left: calc(50% - 760px);
        }

        &__main {
            color: $dark-blue;
            background: transparent;
            z-index: 20;
            height: rem(360);
            position: relative;
            display: flex;
            flex-direction: column;
        }

        &__wrap {
            margin: auto 0;
        }

        &__content {
            width: rem(360);
        }
    }

    @include media-breakpoint-up(xl) {

        &__media {
            left: calc(50% - 960px);
            height: 480px;
            width: 1920px;
        }

        &__main {
            height: 480px;
        }

        &__content {
            width: rem(600);
        }

        &__header {
            font-size: rem(42);
            line-height: rem(52);
            margin-bottom: rem(42);

            strong {
                font-size: rem(64);
                line-height: rem(72);
                margin-top: rem(24);
            }
        }
    }

    @include media-breakpoint-up(xxl) {

        &__main {
            height: 560px;
        }

        &__media {
            left: 0;
            height: 560px;
            width: 100%;
        }
    }
}

.about {
    position: relative;
    padding: rem(60) 0;

    .container {
        position: relative;
    }

    &__media {
        display: none;
    }

    &__content {
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(30);

        p {

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__button {
        padding-top: rem(36);
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: rem(16);
            }
        }
    }

    &__icon {
        fill: $red;
        top: rem(6);
        font-size: 0;
        line-height: 0;
        height: rem(24);
        position: relative;
        margin-right: rem(16);
        display: inline-block;
        flex-shrink: 0;
    }

    @include media-breakpoint-up(md) {
        padding: rem(80) 0 rem(60) 0;
    }

    @include media-breakpoint-up(lg) {
        padding: rem(100) 0 rem(60) 0;

        &__wrap {
            display: flex;
            align-items: center;
        }

        &__media {
            display: block;
            flex-shrink: 0;
            width: rem(400);
        }

        &__content {
            padding-left: rem(60);
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem(120) 0 rem(80) 0;

        &__media {
            width: rem(600);
        }

        &__wrap {
            position: relative;

            &:before {
                opacity: 0.2;
                left: calc(50% - 900px);
                top: 0;
                z-index: 1;
                content: '';
                width: 182px;
                height: 134px;
                position: absolute;
                background: url("../img/pattern.svg") 0 0 no-repeat;
            }

            &:after {
                opacity: 0.2;
                bottom: rem(40);
                right: calc(50% - 1000px);
                width: 287px;
                height: 287px;
                z-index: 1;
                content: '';
                position: absolute;
                background: url("../img/pattern2.svg") 0 0 no-repeat;
            }
        }
    }
}

.section {
    position: relative;
    padding-top: rem(40);
    padding-bottom: rem(40);

    &__header {
        font-weight: 600;
        font-size: rem(32);
        line-height: rem(44);
        text-align: center;
        margin-bottom: rem(40);
    }

    .container {
        z-index: 20;
        position: relative;
    }

    &__group {
        margin-bottom: rem(60);
    }

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: rem(24);
        line-height: rem(36);
        margin-bottom: rem(30);
    }

    &__footer {
        padding-top: rem(40);
    }

    &--gradient {

    }

    &--gray {
        background: #F4F5F7;
    }

    &--rose {
        background: linear-gradient(55.95deg, rgba(255, 63, 58, 0.2) 0%, rgba(247, 94, 5, 0.2) 100%);
    }

    @include media-breakpoint-up(md) {

        &--first {
            padding-top: rem(80);
        }

        &--last {
            padding-bottom: rem(80);
        }

        &__header {

            &--flex-md {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem(80);
        padding-bottom: rem(80);

        &--first {
            padding-top: rem(120);
        }

        &--last {
            padding-bottom: rem(120);
        }

        &__header {
            font-size: rem(44);
            line-height: rem(52);
            margin-bottom: rem(60);
        }

        &__title {
            margin-bottom: rem(48);
        }

        &__group {
            margin-bottom: rem(90);
        }

        &__footer {
            padding-top: rem(60);
        }
    }
}

.section-view-sm {
    margin-top: rem(40);

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.section-view-md {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
    }
}

.teachers-group {
    display: grid;
    grid-row-gap: rem(40);
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
        grid-row-gap: rem(48);
        grid-column-gap: rem(48);
    }
}

.teacher {
    color: $dark;
    display: block;
    padding: rem(30);
    font-size: rem(14);
    line-height: rem(20);
    text-align: center;
    overflow: hidden;
    border-radius: 8px;
    background: #F4F5F7;
    text-decoration: none;
    border: 1px solid rgba(255, 63, 58, 0.5);
    box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
    transition: .3s;

    &:hover {
        color: $dark;
        text-decoration: none;
     //   border-color: rgba(255, 63, 58, 0.5);
        transition: .3s;
    }

    &__media {
        width: rem(180);
        height: rem(180);
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed $dark-blue;
    }

    &__image {
        display: block;
        width: rem(160);
        height: rem(160);
        overflow: hidden;
        border-radius: 50%;
        flex-shrink: 0;
    }

    &__play {
        fill: $red;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        z-index: 10;
        width: 48px;
        height: 48px;
        display: block;
        position: absolute;
    }

    &__content {
        display: block;
        padding-top: rem(20);
    }

    &__name {
        display: block;
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(24);
    }

    &__info {
        display: block;
        margin-top: rem(10);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;

        &__media {
            margin: 0;
            flex-shrink: 0;
        }

        &__content {
            text-align: left;
            padding: 0 0 0 rem(30);
        }
    }

    @include media-breakpoint-up(xl) {
        font-size: rem(16);
        line-height: rem(24);

        &__name {
            font-size: rem(22);
            line-height: rem(26);
        }
    }
}

.courses {
    display: grid;
    grid-row-gap: rem(24);
    grid-column-gap: rem(16);
    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-up(md) {
        grid-row-gap: rem(24);
        grid-column-gap: rem(24);
        grid-template-columns: 1fr 1fr 1fr  1fr 1fr 1fr;

        .course-item {
            grid-column: span 2;

            &:nth-child(4),
            &:nth-child(5) {
                grid-column: span 3;
            }
        }
    }

    @include media-breakpoint-up(lg) {
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        .course-item {
            grid-column: span 1;

            &:nth-child(4),
            &:nth-child(5) {
                grid-column: span 1;
            }
        }
    }
}

.course-item {
    display: block;
    color: $dark-blue;
    padding: rem(30) rem(20);
    border-radius: 8px;
    text-align: center;
    background: $white;
    text-decoration: none;
    border: 1px solid #E5E8ED;
    transition: .3s;

    &__media {
        font-size: 0;
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        display: block;
        width: rem(48);
        height: rem(48);
        flex-shrink: 0;
    }

    &__name {
        display: block;
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(26);
        margin-top: rem(20);
    }

    &:hover {
        color: $dark-blue;
        text-decoration: none;
        border-color: rgba(255, 63, 58, 0.5);
        transition: .3s;
    }

    @include media-breakpoint-up(xl) {

        &__name {
            font-size: rem(18);
        }
    }
}

.items-group {
    display: grid;
    grid-row-gap: rem(24);
    grid-column-gap: rem(16);
    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-up(md) {
        grid-row-gap: rem(24);
        grid-column-gap: rem(24);
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include media-breakpoint-up(lg) {
    }

    @include media-breakpoint-up(xl) {
        grid-row-gap: rem(32);
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.item {
    color: $dark-blue;
    display: block;
    overflow: hidden;
    border-radius: 4px;
    background: $white;
    text-decoration: none;
    border: 1px solid #E5E8ED;
    box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08);
    transition: .3s;

    &:hover {
        color: $dark-blue;
        text-decoration: none;
        box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
        transition: .3s;
    }

    &__media {
        display: block;
        overflow: hidden;
    }

    &__content {
        padding: rem(12);
    }

    &__title {
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(24);
    }

    &__text {
        color: #424551;
        font-size: rem(14);
        line-height: rem(18);
        margin-top: rem(4);
    }

    &__users {
        color: #424551;
        fill: #424551;
        margin-top: rem(8);
        font-size: rem(14);
        line-height: rem(14);
        white-space: nowrap;
        display: flex;
        align-items: center;

        i {
            font-size: 0;
            line-height: 0;
            height: rem(14);
            flex-shrink: 0;
            margin-right: rem(8);
            display: inline-block;
        }

        span {
            display: inline-block;
        }
    }
}

.post-group {
    display: grid;
    grid-row-gap: rem(32);
    grid-column-gap: rem(16);
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include media-breakpoint-up(lg) {
        grid-column-gap: rem(24);
    }
}

.post-item {
    font-size: rem(14);

    &__image {
        display: block;
        overflow: hidden;
        border-radius: 4px;
    }

    &__content {
        padding-top: rem(16);
    }

    &__date {
        fill: $gray;
        color: $gray;
        font-size: rem(13);
        line-height: rem(16);
        display: flex;
        align-items: center;
        margin-bottom: rem(8);

        i {
            font-size: 0;
            line-height: 0;
            height: rem(16);
            flex-shrink: 0;
            margin-right: rem(8);
            display: inline-block;
        }
    }

    &__title {
        display: block;
        color: $dark-blue;
        font-weight: 700;
        font-size: rem(18);
        line-height: 150%;
        text-decoration: none;
        margin-bottom: rem(8);

        &:hover {
            color: $red;
            text-decoration: none;
        }
    }

    &__text {
        color: $gray;
        margin-bottom: rem(8);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem(16);

        &__title {
            font-size: rem(20);
        }
    }
}

.comments {
    position: relative;

    &__main {
        z-index: 10;
        margin: 0 rem(-16);
        position: relative;
    }

    &__nav {
        top: calc(50% - 48px);
        z-index: 50;
        display: none;
        position: absolute;

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }
    }

    .swiper-slide {
        padding: rem(8) rem(16) rem(16) rem(16);
    }

    &__pagination {
        margin-top: rem(16);
    }

    @include media-breakpoint-up(xl) {

        &:before {
            top: -140px;
            left: -200px;
            z-index: 1;
            content: '';
            width: 146px;
            height: 146px;
            position: absolute;
            background: url("../img/pattern_circles_white.svg") 0 0 no-repeat;
        }

        &:after {
            left: -20px;
            bottom: -30px;
            z-index: 1;
            content: '';
            width: 146px;
            height: 105px;
            position: absolute;
            background: url("../img/pattern_dots_white.svg") 0 0 no-repeat;
        }

        &__main {
            width: rem(1052);
            margin: 0 auto;
        }

        &__nav {
            display: block;
        }
    }
}

.comment {
    font-size: rem(14);
    background: $white;
    border-radius: 4px;
    position: relative;
    padding: rem(48) rem(20) rem(30) rem(20);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);

    &:before {
        top: rem(20);
        left: rem(20);
        z-index: 1;
        content: '';
        width: 29px;
        height: 20px;
        position: absolute;
        background: url("../img/braces.svg") 0 0 no-repeat;
    }

    &__footer {
        margin-top: rem(24);
    }

    &__author {
        display: flex;
        align-items: center;
        margin-bottom: rem(16);
    }

    &__avatar {
        display: block;
        width: rem(36);
        height: rem(36);
        overflow: hidden;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: rem(8);
        fill: $dark-blue;
    }

    &__user {
        color: $dark-blue;
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(20);
    }

    @include media-breakpoint-up(sm) {

        &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__author {
            margin: 0 rem(24) 0 0;
        }
    }

    @include media-breakpoint-up(md) {
        font-size: rem(16);
        line-height: rem(24);
        padding: rem(36) rem(48) rem(36) rem(72);

        &:before {
            top: rem(44);
            left: rem(24);
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem(64) rem(104) rem(64) rem(162);

        &:before {
            top: rem(64);
            left: rem(104);
        }
    }
}

.swiper-pagination {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    font-size: 0;
    line-height: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
        margin-top: rem(24);
    }
}

.swiper-pagination-bullet {
    width: 24px;
    height: 4px;
    display: block;
    cursor: pointer;
    border-radius: 4px;
    background: #B3B7BC;
    margin: 0 rem(4);

    @include media-breakpoint-up(md) {
        width: 30px;
        margin: 0 rem(6);
    }
}

.swiper-pagination-bullet-active {
    background-color: #424551;
}


.avatar {
    width: 80px;
    height: 80px;
    display: block;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
}

.avatar-change {
    display: flex;
    align-items: center;

    &__buttons {
        padding-left: rem(20);
    }
}

.input-list {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {

        &:not(:last-child) {
            margin-bottom: rem(12);
        }
    }
}

.order-form {
    max-width: rem(600);

    &__row {
        margin-bottom: rem(24);
    }

    &__label {
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(20);
        margin-bottom: rem(8);
    }

    &__total {
        text-align: right;
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(26);
    }

    &__button {
        text-align: right;
        margin-top: rem(32);
        padding-top: rem(32);
        border-top: 1px solid #E5E8ED;

        .btn {
            min-width: rem(260);
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(60);
    }
}

@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}
